import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
	
  public userInfo:any = "";	

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private menu: MenuController,
    private authenticationService: AuthenticationService, 
    private router: Router 
  ) {
	this.storage.get('auth-token').then((data) => {
	   console.log("UserInfo", data);
	   this.userInfo = data;
 	});
    this.initializeApp();
  }

  logout() {	  
	  
   this.userInfo = null;	  
   this.menu.close();	
   this.authenticationService.logout();  	   	

  }  

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.authenticationService.authenticationState.subscribe(state => {
        if (state) {
          //this.router.navigate(['members']);
		    this.router.navigateByUrl('/tabs/home');          
        } else {
			this.router.navigateByUrl('/');
        }
      });   


    });
  }
}

