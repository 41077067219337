import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
  path: 'members',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'recover',
    loadChildren: () => import('./recover-pass/recover-pass.module').then( m => m.RecoverPassPageModule)
  },
  {
    path: 'members/profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  
  
  
  {
    path: 'members/vehicle-telemetry-pilot',
    loadChildren: () => import('./vehicle-telemetry-pilot/vehicle-telemetry-pilot.module').then( m => m.VehicleTelemetryPilotPageModule)
  },
  {
    path: 'members/vehicle-telemetry-vehicle',
    loadChildren: () => import('./vehicle-telemetry-vehicle/vehicle-telemetry-vehicle.module').then( m => m.VehicleTelemetryVehiclePageModule)
  },
  {
    path: 'members/vehicle-map',
    loadChildren: () => import('./vehicle-map/vehicle-map.module').then( m => m.VehicleMapPageModule)
  },
  {
    path: 'members/vehicle-event',
    loadChildren: () => import('./vehicle-event/vehicle-event.module').then( m => m.VehicleEventPageModule)
  }
  
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
